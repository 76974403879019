import { MakeCall } from './createMakeCall';

export interface SimilarListing {
  id: number;
}

const getSimilarListings =
  (makeCall: MakeCall) =>
  ({ unitTypeId }: { unitTypeId: number }) =>
    makeCall<SimilarListing[]>(
      'GET',
      `/api/v2/unit-type/${unitTypeId}/similar-ids`,
    );

export { getSimilarListings };
