import { handleActions } from '@housinganywhere/safe-redux';

import { User } from '@ha/api/v2/types/User';
import { ImpersonationInfo } from '@ha/api/v2/types/UserInformation';

import { AuthLogicActions } from './actions';
import { ActionsType as FetchUserBillingInfoActions } from './actions/fetchUserBillingInfo';
import {
  AuthLogicTypes,
  FetchUserBillingInfoTypes,
  ProfileEditActionTypes,
} from './constants';
import { UserInfoResponse } from './types';

export interface AuthLogicState {
  user: User | null;
  userInformation: UserInfoResponse | null;
  language: string;
  isAuthenticated: boolean;
  impersonatingAdmin: ImpersonationInfo | null;
  isUserBillingInfoLoading: boolean;
}

export const initialState = {
  user: null,
  userInformation: null,
  language: 'en',
  isAuthenticated: false,
  impersonatingAdmin: null,
  isUserBillingInfoLoading: false,
};

type ActionTypes =
  | AuthLogicTypes
  | FetchUserBillingInfoTypes
  | ProfileEditActionTypes.updateUser;

type Actions = AuthLogicActions | FetchUserBillingInfoActions;

const reducer = handleActions<AuthLogicState, ActionTypes, Actions>(
  {
    [AuthLogicTypes.initUser]: (s, action) => ({
      ...s,
      user: {
        ...s.user,
        ...action.payload.user,
      },
      userInformation: {
        ...s.userInformation,
        ...action.payload.userInformation,
      },
      impersonatingAdmin: action.payload.userInformation?.impersonation || null,
      isAuthenticated: true,
    }),
    [AuthLogicTypes.updateUser]: (s, action) => ({
      ...s,
      user: {
        ...(s.user as User),
        ...action.payload,
      },
    }),
    [FetchUserBillingInfoTypes.start]: s => ({
      ...s,
      isUserBillingInfoLoading: true,
    }),
    [FetchUserBillingInfoTypes.fail]: s => ({
      ...s,
      isUserBillingInfoLoading: false,
    }),
    [FetchUserBillingInfoTypes.done]: (s, action) => {
      return {
        ...s,
        userInformation: {
          ...s.userInformation,
          ...action.payload,
        },
        isUserBillingInfoLoading: false,
      };
    },
    [ProfileEditActionTypes.updateUser]: (
      s,
      {
        payload,
      }: {
        type: string;
        payload: User;
      },
    ) => ({
      ...s,
      user: s.user && {
        ...s.user,
        ...payload,
      },
    }),
  },
  initialState,
);

export { reducer };
