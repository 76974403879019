import { reset } from '@hbf/analytics';

import { CookieService } from 'ha/services/cookie';
import { removeFromStorage } from 'ha/utils/storage';

import { filterMatchmakingCookieHelper } from 'ha/modules/FilterBasedMatchmaking/helpers';
import { savedSearchLocalStore } from 'ha/modules/SavedSearches/savedSearchLocalStore';

import { BILLING_INFO_STORAGE_KEY } from './constants';

export function clearUserData(cookie: CookieService) {
  // reset both user id and anonymous id
  reset(true);

  removeFromStorage(BILLING_INFO_STORAGE_KEY);

  // removed Saved Search from localStorage once user logs out, so next time user can start with a clean slate
  savedSearchLocalStore.remove();

  filterMatchmakingCookieHelper.remove(cookie);
}

export function clearUserDataOnNavigation(cookie: CookieService) {
  window.addEventListener('beforeunload', () => clearUserData(cookie));
}
