import type { MakeCall } from '../createMakeCall';
import { ReviewReportResponse } from '../types/surveyReviews/Reports';

/**
 * Function to unsubscribe from a specific survey.
 */
export const unsubscribeFromSurvey =
  (makeCall: MakeCall) =>
  ({ surveyId }: { surveyId: string }) =>
    makeCall<ReviewReportResponse>(
      'POST',
      `/api/v2/review/survey/${surveyId}/unsubscribe`,
    );
