import { OCCUPATION } from '@ha/api/v2/types/Occupation';
import { defineKey as T } from '@ha/intl';

export const OCCUPATION_TRANSLATIONS: { [K in OCCUPATION]: string } = {
  [OCCUPATION.other]: T('user.occupation.other'),
  [OCCUPATION.student]: T('user.occupation.student'),
  [OCCUPATION.unknown]: T('user.occupation.unknown'),
  [OCCUPATION.workingProfessional]: T('user.occupation.working_professional'),
};

export { OCCUPATION };
