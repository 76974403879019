const CHECK_INTERVAL = 500; // ms
const TIMEOUT = 5000; // ms

export const waitForOneTrust = () => {
  return new Promise<typeof OneTrust>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout;

    const intervalId = setInterval(() => {
      if (window.OneTrust) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        resolve(window.OneTrust);
      }
    }, CHECK_INTERVAL);

    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      reject(new Error('OneTrust not found within timeout'));
    }, TIMEOUT); // Adjust the timeout as needed
  });
};
