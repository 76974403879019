import { IntegrationNames } from '@hbf/analytics/lib/constants';

export enum CampaignKeywords {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
}

export const UNIVERSITIES = [
  'aalto.fi',
  'abo.fi',
  'ac.at',
  'ac.be',
  'ahk.nl',
  'aidwageningen.nl',
  'akafoe.de',
  'aku-aalborg.dk',
  'amc.nl',
  'anadolu.edu.tr',
  'ankara.edu.tr',
  'arts.ac.uk',
  'au.dk',
  'avans.nl',
  'bath.ac.uk',
  'bba-edhec.fr',
  'berkeley.edu',
  'betet-kft.hu',
  'bfh.ch',
  'bg.ac.rs',
  'cahvilentum.nl',
  'cbs.dk',
  'ccfs-sorbonne.fr',
  'chalmers.se',
  'charite.de',
  'che.nl',
  'chimie-paristech.fr',
  'codarts.nl',
  'comune.torino.it',
  'cord.edu',
  'cphbusiness.dk',
  'cranfield.ac.uk',
  'dauphine.fr',
  'dehaagsehogeschool.nl',
  'dtu.dk',
  'ec-nantes.fr',
  'ed.ac.uk',
  'edhec-ge.com',
  'edhec-grande-ecole.com',
  'edmundo.ro',
  'em-lyon.com',
  'ens-cachan.fr',
  'ens2m.fr',
  'erasmusmanager.it',
  'erasmusmc.nl',
  'erasmusplus.org.uk',
  'erasmuspraktika.de',
  'erasmustorino.it',
  'esade.edu',
  'esce.fr',
  'escom.fr',
  'esegunibo.org',
  'esn.pl',
  'ethz.ch',
  'eur.nl',
  'eures.dk',
  'europa-uni.de',
  'excasljmu.ac.uk',
  'fcq.uach.mx',
  'ffzg.hr',
  'fh-bielefeld.de',
  'fh-dortmund.de',
  'fh-fulda.de',
  'fh-gesundheitsberufe.at',
  'fh-has.de',
  'fh-kaernten.at',
  'fh-koeln.de',
  'fhnw.ch',
  'fhstp.ac.at',
  'folkwang-uni.de',
  'fontys.edu',
  'fu.uni-lj.si',
  'fz-juelich.de',
  'gfs.se',
  'gju.edu.jo',
  'gotastudentkar.se',
  'gu.se',
  'gumed.edu.pl',
  'hanken.fi',
  'hannan-u.ac.jp',
  'hanze.nl',
  'harvard.edu',
  'has.nl',
  'hasnl',
  'haw-hamburg.de',
  'hcu-hamburg.de',
  'henallux.be',
  'hesge.ch',
  'hfm-berlin.de',
  'hhs.nl',
  'hhs.se',
  'hi.is',
  'hj.se',
  'hkbu.edu.hk',
  'hku.nl',
  'hm.edu',
  'homeexchangeuniversity.com',
  'hro.nl',
  'hs-fulda.de',
  'hs-pforzheim.de',
  'hse.ru',
  'hsleiden.nl',
  'hsr.ch',
  'hu-berlin.de',
  'hult.edu',
  'huwisu.de',
  'hz.nl',
  'iaft.net',
  'iavans.nl',
  'ibero.mx',
  'icmab.es',
  'iep-strasbourg.fr',
  'iep.nccu.edu.tw',
  'inforjeunes.eu',
  'ing-ruhr-uni-bochum.de',
  'inholland.nl',
  'intermesh.net',
  'internationalcommunity.dk',
  'isap1.fr',
  'itu.dk',
  'itu.edu.tr',
  'kea.dk',
  'kedgebs.com',
  'kgh.on.ca',
  'ki.se',
  'kihousing.se',
  'kit.edu',
  'kk.dk',
  'kkh.se',
  'kodan.dk',
  'kth.se',
  'ku.dk',
  'kuleuven.be',
  'laurea.fi',
  'leiden.edu',
  'leidenuniv.nl',
  'lejeboligerdanmark.dk',
  'lemoni.fr',
  'llpmanager.it',
  'logement.epfl.ch',
  'lu.se',
  'luca-arts.be',
  'luiss.edu',
  'luiss.it',
  'maastrichtuniversity.nl',
  'mah.se',
  'marquette.edu',
  'mediosuag.mx',
  'mendelu.cz',
  'mhmk-international.org',
  'misti.mit.edu',
  'mit.edu',
  'muni.cz',
  'my.ucam.edu',
  'ncl.ac.uk',
  'nctu.edu.tw',
  'nhh.no',
  'nhtv.nl',
  'nist.ac.th',
  'nuigalway.ie',
  'osu.edu',
  'ouhsc.edu',
  'ox.ac.uk',
  'pau.edu.tr',
  'phbern.ch',
  'princeton.edu',
  'queensu.ca',
  'rotterdamuas.com',
  'rsm.nl',
  'ru.nl',
  'rub.de',
  'ruc.dk',
  'rug.nl',
  'ruhr-uni-bochum.de',
  'saxion.edu',
  'saxion.nl',
  'scambieuropei.info',
  'sciencespo-lille.com',
  'sciencespo-lille.eu',
  'sciencespo-saintgermainenlaye.fr',
  'sdu.dk',
  'sin-online.nl',
  'snycosmos.com',
  'sou-lj.si',
  'ssu.ac.kr',
  'stenden.com',
  'studbocconi.it',
  'studentboet.se',
  'studenten.net',
  'studentify.nl',
  'studentska-org.si',
  'sussex.ac.uk',
  'tamu.edu',
  'thehagueuniversity.com',
  'tilburguniversity.edu',
  'tu-dortmund.de',
  'tu-dresden.de',
  'tudelft.nl',
  'tue.nl',
  'tuk-tam.bg',
  'uach.mx',
  'uag.edu',
  'uag.mx',
  'uam.es',
  'uc3m.es',
  'ucam.edu',
  'ucc.dk',
  'ucd.ie',
  'ucf.edu',
  'uchicago.edu',
  'ucm.es',
  'ucn.dk',
  'ucnorth.dk',
  'uco.es',
  'ucscit',
  'ucsj.dk',
  'ucv.es',
  'udc.es',
  'ufl.edu',
  'ufrgs.br',
  'ufvcom',
  'ugent.be',
  'ugr.es',
  'ugt.org',
  'uhasselt.be',
  'uio.no',
  'uit.no',
  'uji.es',
  'ulb.ac.be',
  'ulg.ac.be',
  'ulisboa.pt',
  'um.es',
  'unamur.be',
  'unc.edu',
  'uncc.edu',
  'unesp.br',
  'uni-bremen.de',
  'uni-corvinus.hu',
  'uni-freiburg.de',
  'uni-giessen.de',
  'uni-goettingen.de',
  'uni-graz.at',
  'uni-heidelberg.de',
  'uni-hildesheim.de',
  'uni-kiel.de',
  'uni-koeln.de',
  'uni-lj.si',
  'uni-magdeburg.de',
  'uni-mainz.de',
  'uni-muenchen.de',
  'uni-regensburg.de',
  'uni-stuttgart.de',
  'uni-tuebingen.de',
  'uni-wuerzburg.de',
  'uni.li',
  'uniba.sk',
  'unibe.ch',
  'unibo.it',
  'unibocconi.eu',
  'unibocconi.it',
  'unica-network.eu',
  'unicil.fr',
  'unict.it',
  'unifi.it',
  'unify.bg',
  'unige.ch',
  'unimaas.nl',
  'unime.it',
  'unimelb.edu.au',
  'unina.it',
  'unina2.it',
  'unine.ch',
  'unior.traineeship.it',
  'unipi.it',
  'unipr.it',
  'unipv.eu',
  'unirc.it',
  'uniroma1.it',
  'uniroma2.it',
  'uniroma3.it',
  'unisi.it',
  'unitn.it',
  'unito.it',
  'units.it',
  'uniud.it',
  'univ-bpclermont.fr',
  'univ-lille2.fr',
  'univ-lyon2.fr',
  'univ-rouen.fr',
  'univ-smb.fr',
  'univ-tours.fr',
  'univ-valenciennes.fr',
  'universia.es',
  'universia.net',
  'universitarios.cl',
  'univie.ac.at',
  'univpm.it',
  'unl.pt',
  'uoc.edu',
  'up.pt',
  'upf.edu',
  'upm.es',
  'upr.si',
  'upv.es',
  'urjc.es',
  'usc.edu',
  'usi.ch',
  'utexas.edu',
  'uth.gr',
  'utl.pt',
  'utu.fi',
  'uu.nl',
  'uv.es',
  'uva.fi',
  'uva.nl',
  'uvh.nl',
  'uvigo.gal',
  'uvm.edu',
  'uwe.ac.uk',
  'uwesu.org',
  'uwo.ca',
  'uzh.ch',
  'vhluniversity.com',
  'vhluniversity.de',
  'via.dk',
  'vu.nl',
  'vumc.nl',
  'wageningen.nl',
  'wageningenur.nl',
  'welcome.univ-lorraine.fr',
  'welcomeoffice.fvg.it',
  'windesheim.nl',
  'windesheimnl',
  'wu.ac.at',
  'wur.nl',
  'yorku.ca',
  'zmt-bremen.de',
];

export enum RudderstackEventNames {
  OrderCompleted = 'Order Completed',
  CheckoutStarted = 'Checkout Started',
  PaymentInfoEntered = 'Payment Info Entered',
  PromotionClicked = 'Promotion Clicked',
  PromotionViewed = 'Promotion Viewed',
  ProductAdded = 'Product Added',
  ProductAddedToWishlist = 'Product Added To Wishlist',
  ProductViewed = 'Product Viewed',
  ProductListViewed = 'Product List Viewed',
  ProductsSearched = 'Products Searched',
  ListingCardClicked = 'Listing Card Clicked',
}

export const CUSTOM_EVENT_NAMES = Object.freeze({
  PINTEREST: {
    SIGN_UP: 'Signup',
  },
  REDDIT: {
    LEAD: 'Lead',
    VIEW_CONTENT: 'View Content',
    SEARCH: 'Search',
  },
  SNAP: {
    SUBSCRIBE: 'SUBSCRIBE',
  },
});

export const STRICTLY_NECESSARY_DESTINATIONS = {
  All: false,
  [IntegrationNames.Intercom]: true,
  [IntegrationNames.Mixpanel]: true,
  [IntegrationNames.Rockerbox]: true,
};
