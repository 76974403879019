import { MakeCall } from './createMakeCall';
import {
  RentGuaranteeDocumentCategory,
  RequestUserDocumentResponse,
  UserDocumentCategory,
} from './types/UserDocument';

const postUserDocumentRequest =
  (makeCall: MakeCall) =>
  (
    userId: 'me' | number,
    data: {
      category: UserDocumentCategory | RentGuaranteeDocumentCategory;
    },
  ) =>
    makeCall<RequestUserDocumentResponse>(
      'POST',
      `/api/v2/user/${userId}/document/request`,
      {
        data,
      },
    );
export { postUserDocumentRequest };
