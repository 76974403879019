import { defineKey as T } from '@ha/intl';

export { ApiError } from './ApiError';
export { BaseError } from './BaseError';
export { RequestError } from './RequestError';
export type { ErrorResponse } from './RequestError';

export const ApiV2ErrorCodes = {
  _already_exists: '_already_exists',
  _at_least_one_condition_required: '_at_least_one_condition_required',
  _content_type_invalid: '_content_type_invalid',
  _country_code_unknown: '_country_code_unknown',
  _country_not_found: '_country_not_found',
  _id_in_path_invalid: '_id_in_path_invalid',
  _incomplete_input: '_incomplete_input',
  _input_too_big: '_input_too_big',
  _invalid_input: '_invalid_input',
  _key_required: '_key_required',
  _limit_wrong: '_limit_wrong',
  _list_empty: '_list_empty',
  _list_minimum_one: '_list_minimum_one',
  _maximum_length_exceeded: '_maximum_length_exceeded',
  _offset_with_afterid: '_offset_with_afterid',
  _parameter_in_path_invalid: '_parameter_in_path_invalid',
  _payload_empty: '_payload_empty',
  _reserved_for_internal: '_reserved_for_internal',
  _reserved_for_owners: '_reserved_for_owners',
  _too_long: '_too_long',
  _too_short: '_too_short',
  _url_expected_only_path: '_url_expected_only_path',
  _value_invalid: '_value_invalid',
  _value_mutually_exclusive: '_value_mutually_exclusive',
  _value_required: '_value_required',
  _value_unknown: '_value_unknown',
  _password_not_new: '_password_not_new',
  _password_change_required: '_password_change_required',
  acl_api_key_cannot_be_verified: 'acl_api_key_cannot_be_verified',
  acl_api_key_invalid: 'acl_api_key_invalid',
  acl_result_type_unrecognized: 'acl_result_type_unrecognized',
  billing_price_codes_duplicates: 'billing_price_codes_duplicates',
  billing_price_codes_mix: 'billing_price_codes_mix',
  billing_price_codes_no_recurring: 'billing_price_codes_no_recurring',
  billing_subscription_already_active: 'billing_subscription_already_active',
  billing_subscription_inactive: 'billing_subscription_inactive',
  billing_subscription_not_found: 'billing_subscription_not_found',
  conversation_already_exists: 'conversation_already_exists',
  conversation_requires_subscription: 'conversation_requires_subscription',
  conversation_special_offer_no_changes:
    'conversation_special_offer_no_changes',
  conversation_special_offer_no_pricing:
    'conversation_special_offer_no_pricing',
  conversation_state_unknown: 'conversation_state_unknown',
  conversation_state_wrong: 'conversation_state_wrong',
  currency_not_supported: 'currency_not_supported',
  date_in_the_past: 'date_in_the_past',
  date_range_invalid: 'date_range_invalid',
  date_too_far_in_the_future: 'date_too_far_in_the_future',
  existing_active_subscription: 'existing_active_subscription',
  expansion_required_conversationID_onBoardingLink:
    'expansion_required_conversationID_onBoardingLink',
  file_mime_wrong: 'file_mime_wrong',
  file_multiple: 'file_multiple',
  file_not_present: 'file_not_present',
  file_retrieving_error: 'file_retrieving_error',
  file_too_big: 'file_too_big',
  file_upload_error: 'file_upload_error',
  geo_address_communication_error: 'geo_address_communication_error',
  geo_address_not_found: 'geo_address_not_found',
  geo_address_street_required: 'geo_address_street_required',
  geo_cities_max_population_exceeded: 'geo_cities_max_population_exceeded',
  geo_cities_multiple_values: 'geo_cities_multiple_values',
  geo_cities_values_required: 'geo_cities_values_required',
  geo_geoname_not_found: 'geo_geoname_not_found',
  geo_unknown_type: 'geo_unknown_type',
  invalid_signature: 'invalid_signature',
  listing_collection_expected_order_by_id:
    'listing_collection_expected_order_by_id',
  listing_collection_from_to_required: 'listing_collection_from_to_required',
  listing_collection_price_max_lower_min:
    'listing_collection_price_max_lower_min',
  listing_contact_own: 'listing_contact_own',
  listing_contact_partner: 'listing_contact_partner',
  listing_cost_badly_defined: 'listing_cost_badly_defined',
  listing_cost_payable_at_unknown: 'listing_cost_payable_at_unknown',
  listing_cost_type_unknown: 'listing_cost_type_unknown',
  listing_cost_value_not_set: 'listing_cost_value_not_set',
  listing_cost_value_set: 'listing_cost_value_set',
  listing_country_not_supported: 'listing_country_not_supported',
  listing_exclusion_error: 'listing_exclusion_error',
  listing_exclusion_from_to_required: 'listing_exclusion_from_to_required',
  listing_ics_url_not_found: 'listing_ics_url_not_found',
  listing_instant_forbidden: 'listing_instant_forbidden',
  listing_no_external_url_for_ppc: 'listing_no_external_url_for_ppc',
  listing_not_found: 'listing_not_found',
  listing_not_partner: 'listing_not_partner',
  listing_photo_change_values_required: 'listing_photo_change_values_required',
  listing_photo_formating_invalid: 'listing_photo_formating_invalid',
  listing_photo_id_invalid: 'listing_photo_id_invalid',
  listing_photo_id_unknown: 'listing_photo_id_unknown',
  listing_photo_no_order: 'listing_photo_no_order',
  listing_pricing_monthly_insufficient_data:
    'listing_pricing_monthly_insufficient_data',
  listing_pricing_monthly_period_gaps: 'listing_pricing_monthly_period_gaps',
  listing_pricing_no_data: 'listing_pricing_no_data',
  listing_survey_fields_required: 'listing_survey_fields_required',
  listing_unavailable_period: 'listing_unavailable_period',
  listing_video_add_authors_required: 'listing_video_add_authors_required',
  listing_video_add_titles_required: 'listing_video_add_titles_required',
  listing_video_id_invalid: 'listing_video_id_invalid',
  listing_video_id_unknown: 'listing_video_id_unknown',
  listing_video_no_order: 'listing_video_no_order',
  listing_video_not_found: 'listing_video_not_found',
  outboundfeed_exclusive_at_and_to_from:
    'outboundfeed_exclusive_at_and_to_from',
  outboundfeed_exclusive_now_and_days: 'outboundfeed_exclusive_now_and_days',
  outboundfeed_from_less_than_to: 'outboundfeed_from_less_than_to',
  payment_method_multiple: 'payment_method_multiple',
  payment_method_not_found: 'payment_method_not_found',
  payment_method_required: 'payment_method_required',
  payment_method_unsupported: 'payment_method_unsupported',
  payment_provider_type_unknown: 'payment_provider_type_unknown',
  payment_type_forbidden: 'payment_type_forbidden',
  payout_method_delete_has_active_payouts:
    'payout_method_delete_has_active_payouts',
  payout_method_delete_linked_to_listing:
    'payout_method_delete_linked_to_listing',
  payout_method_delete_linked_to_payment_request:
    'payout_method_delete_linked_to_payment_request',
  payout_method_delete_is_default_for_currency:
    'payout_method_delete_is_default_for_currency',
  poi_university_type_expected: 'poi_university_type_expected',
  poi_values_required: 'poi_values_required',
  recaptcha_outdated_check: 'recaptcha_outdated_check',
  recaptcha_score_too_low: 'recaptcha_score_too_low',
  recaptcha_unsuccessful: 'recaptcha_unsuccessful',
  recaptcha_validation_error: 'recaptcha_validation_error',
  recaptcha_wrong_hostname: 'recaptcha_wrong_hostname',
  recaptcha_wrong_token: 'recaptcha_wrong_token',
  rental_conditions_source_multiple: 'rental_conditions_source_multiple',
  retrieving_subscription: 'retrieving_subscription',
  stripe_intent_unknown: 'stripe_intent_unknown',
  stripe_verification_error: 'stripe_verification_error',
  tenancy_engagement_type_used: 'tenancy_engagement_type_used',
  tenancy_tenant_landlord_equal_uuid: 'tenancy_tenant_landlord_equal_uuid',
  user_account_deleted: 'user_account_deleted',
  user_already_verified: 'user_already_verified',
  user_code_expired: 'user_code_expired',
  user_code_used: 'user_code_used',
  user_federated_empty_id: 'user_federated_empty_id',
  user_federated_no_email: 'user_federated_no_email',
  user_i_d_not_verified: 'user_i_d_not_verified',
  user_jwt_verification_error: 'user_jwt_verification_error',
  user_logged_in: 'user_logged_in',
  user_profile_email_already_registered:
    'user_profile_email_already_registered',
  user_profile_email_forbidden: 'user_profile_email_forbidden',
  user_profile_passwords_do_not_match: 'user_profile_passwords_do_not_match',
  user_profile_phone_used: 'user_profile_phone_used',
  user_profile_rooms_less_than_listings:
    'user_profile_rooms_less_than_listings',
  user_profile_users_not_found: 'user_profile_users_not_found',
  user_profile_verification_code_unmatch:
    'user_profile_verification_code_unmatch',
  user_searches_country_code_city_required:
    'user_searches_country_code_city_required',
  user_searches_price_range_invalid: 'user_searches_price_range_invalid',
  user_too_many_documents: 'user_too_many_documents',
  webhook_test_failed: 'webhook_test_failed',
};

/**
 * A list of error code with
 * From https://storage.cloud.google.com/dev-housinganywhere-com/platform/http/v2.html#section/Error-Handling
 */
export const ApiV2Errors: Partial<
  Record<keyof typeof ApiV2ErrorCodes, string>
> = {
  _already_exists: T('apiV2Error._already_exists'),
  _at_least_one_condition_required: T(
    'apiV2Error._at_least_one_condition_required',
  ),
  _content_type_invalid: T('apiV2Error._content_type_invalid'),
  _country_code_unknown: T('apiV2Error._country_code_unknown'),
  _country_not_found: T('apiV2Error._country_not_found'),
  _id_in_path_invalid: T('apiV2Error._id_in_path_invalid'),
  _incomplete_input: T('apiV2Error._incomplete_input'),
  _input_too_big: T('apiV2Error._input_too_big'),
  _invalid_input: T('apiV2Error._invalid_input'),
  _key_required: T('apiV2Error._key_required'),
  _limit_wrong: T('apiV2Error._limit_wrong'),
  _list_empty: T('apiV2Error._list_empty'),
  _list_minimum_one: T('apiV2Error._list_minimum_one'),
  _maximum_length_exceeded: T('apiV2Error._maximum_length_exceeded'),
  _offset_with_afterid: T('apiV2Error._offset_with_afterid'),
  _parameter_in_path_invalid: T('apiV2Error._parameter_in_path_invalid'),
  _payload_empty: T('apiV2Error._payload_empty'),
  _reserved_for_internal: T('apiV2Error._reserved_for_internal'),
  _reserved_for_owners: T('apiV2Error._reserved_for_owners'),
  _too_long: T('apiV2Error._too_long'),
  _too_short: T('apiV2Error._too_short'),
  _url_expected_only_path: T('apiV2Error._url_expected_only_path'),
  _value_invalid: T('apiV2Error._value_invalid'),
  _value_mutually_exclusive: T('apiV2Error._value_mutually_exclusive'),
  _value_required: T('apiV2Error._value_required'),
  _value_unknown: T('apiV2Error._value_unknown'),
  _password_not_new: T('apiV2Error._password_not_new'),
  acl_api_key_cannot_be_verified: T(
    'apiV2Error.acl_api_key_cannot_be_verified',
  ),
  acl_api_key_invalid: T('apiV2Error.acl_api_key_invalid'),
  acl_result_type_unrecognized: T('apiV2Error.acl_result_type_unrecognized'),
  billing_price_codes_duplicates: T(
    'apiV2Error.billing_price_codes_duplicates',
  ),
  billing_price_codes_mix: T('apiV2Error.billing_price_codes_mix'),
  billing_price_codes_no_recurring: T(
    'apiV2Error.billing_price_codes_no_recurring',
  ),
  billing_subscription_already_active: T(
    'apiV2Error.billing_subscription_already_active',
  ),
  billing_subscription_inactive: T('apiV2Error.billing_subscription_inactive'),
  billing_subscription_not_found: T(
    'apiV2Error.billing_subscription_not_found',
  ),
  conversation_already_exists: T('apiV2Error.conversation_already_exists'),
  conversation_requires_subscription: T(
    'apiV2Error.conversation_requires_subscription',
  ),
  conversation_special_offer_no_changes: T(
    'apiV2Error.conversation_special_offer_no_changes',
  ),
  conversation_special_offer_no_pricing: T(
    'apiV2Error.conversation_special_offer_no_pricing',
  ),
  conversation_state_unknown: T('apiV2Error.conversation_state_unknown'),
  conversation_state_wrong: T('apiV2Error.conversation_state_wrong'),
  currency_not_supported: T('apiV2Error.currency_not_supported'),
  date_in_the_past: T('apiV2Error.date_in_the_past'),
  date_range_invalid: T('apiV2Error.date_range_invalid'),
  date_too_far_in_the_future: T('apiV2Error.date_too_far_in_the_future'),
  existing_active_subscription: T('apiV2Error.existing_active_subscription'),
  expansion_required_conversationID_onBoardingLink: T(
    'apiV2Error.expansion_required_conversationID_onBoardingLink',
  ),
  file_mime_wrong: T('apiV2Error.file_mime_wrong'),
  file_multiple: T('apiV2Error.file_multiple'),
  file_not_present: T('apiV2Error.file_not_present'),
  file_retrieving_error: T('apiV2Error.file_retrieving_error'),
  file_too_big: T('apiV2Error.file_too_big'),
  file_upload_error: T('apiV2Error.file_upload_error'),
  geo_address_communication_error: T(
    'apiV2Error.geo_address_communication_error',
  ),
  geo_address_not_found: T('apiV2Error.geo_address_not_found'),
  geo_address_street_required: T('apiV2Error.geo_address_street_required'),
  geo_cities_max_population_exceeded: T(
    'apiV2Error.geo_cities_max_population_exceeded',
  ),
  geo_cities_multiple_values: T('apiV2Error.geo_cities_multiple_values'),
  geo_cities_values_required: T('apiV2Error.geo_cities_values_required'),
  geo_geoname_not_found: T('apiV2Error.geo_geoname_not_found'),
  geo_unknown_type: T('apiV2Error.geo_unknown_type'),
  invalid_signature: T('apiV2Error.invalid_signature'),
  listing_collection_expected_order_by_id: T(
    'apiV2Error.listing_collection_expected_order_by_id',
  ),
  listing_collection_from_to_required: T(
    'apiV2Error.listing_collection_from_to_required',
  ),
  listing_collection_price_max_lower_min: T(
    'apiV2Error.listing_collection_price_max_lower_min',
  ),
  listing_contact_own: T('apiV2Error.listing_contact_own'),
  listing_contact_partner: T('apiV2Error.listing_contact_partner'),
  listing_cost_badly_defined: T('apiV2Error.listing_cost_badly_defined'),
  listing_cost_payable_at_unknown: T(
    'apiV2Error.listing_cost_payable_at_unknown',
  ),
  listing_cost_type_unknown: T('apiV2Error.listing_cost_type_unknown'),
  listing_cost_value_not_set: T('apiV2Error.listing_cost_value_not_set'),
  listing_cost_value_set: T('apiV2Error.listing_cost_value_set'),
  listing_country_not_supported: T('apiV2Error.listing_country_not_supported'),
  listing_exclusion_error: T('apiV2Error.listing_exclusion_error'),
  listing_exclusion_from_to_required: T(
    'apiV2Error.listing_exclusion_from_to_required',
  ),
  listing_ics_url_not_found: T('apiV2Error.listing_ics_url_not_found'),
  listing_instant_forbidden: T('apiV2Error.listing_instant_forbidden'),
  listing_no_external_url_for_ppc: T(
    'apiV2Error.listing_no_external_url_for_ppc',
  ),
  listing_not_found: T('apiV2Error.listing_not_found'),
  listing_not_partner: T('apiV2Error.listing_not_partner'),
  listing_photo_change_values_required: T(
    'apiV2Error.listing_photo_change_values_required',
  ),
  listing_photo_formating_invalid: T(
    'apiV2Error.listing_photo_formating_invalid',
  ),
  listing_photo_id_invalid: T('apiV2Error.listing_photo_id_invalid'),
  listing_photo_id_unknown: T('apiV2Error.listing_photo_id_unknown'),
  listing_photo_no_order: T('apiV2Error.listing_photo_no_order'),
  listing_pricing_monthly_insufficient_data: T(
    'apiV2Error.listing_pricing_monthly_insufficient_data',
  ),
  listing_pricing_monthly_period_gaps: T(
    'apiV2Error.listing_pricing_monthly_period_gaps',
  ),
  listing_pricing_no_data: T('apiV2Error.listing_pricing_no_data'),
  listing_survey_fields_required: T(
    'apiV2Error.listing_survey_fields_required',
  ),
  listing_unavailable_period: T('apiV2Error.listing_unavailable_period'),
  listing_video_add_authors_required: T(
    'apiV2Error.listing_video_add_authors_required',
  ),
  listing_video_add_titles_required: T(
    'apiV2Error.listing_video_add_titles_required',
  ),
  listing_video_id_invalid: T('apiV2Error.listing_video_id_invalid'),
  listing_video_id_unknown: T('apiV2Error.listing_video_id_unknown'),
  listing_video_no_order: T('apiV2Error.listing_video_no_order'),
  listing_video_not_found: T('apiV2Error.listing_video_not_found'),
  outboundfeed_exclusive_at_and_to_from: T(
    'apiV2Error.outboundfeed_exclusive_at_and_to_from',
  ),
  outboundfeed_exclusive_now_and_days: T(
    'apiV2Error.outboundfeed_exclusive_now_and_days',
  ),
  outboundfeed_from_less_than_to: T(
    'apiV2Error.outboundfeed_from_less_than_to',
  ),
  payment_method_multiple: T('apiV2Error.payment_method_multiple'),
  payment_method_not_found: T('apiV2Error.payment_method_not_found'),
  payment_method_required: T('apiV2Error.payment_method_required'),
  payment_method_unsupported: T('apiV2Error.payment_method_unsupported'),
  payment_provider_type_unknown: T('apiV2Error.payment_provider_type_unknown'),
  payment_type_forbidden: T('apiV2Error.payment_type_forbidden'),
  poi_university_type_expected: T('apiV2Error.poi_university_type_expected'),
  poi_values_required: T('apiV2Error.poi_values_required'),
  recaptcha_outdated_check: T('apiV2Error.recaptcha_outdated_check'),
  recaptcha_score_too_low: T('apiV2Error.recaptcha_score_too_low'),
  recaptcha_unsuccessful: T('apiV2Error.recaptcha_unsuccessful'),
  recaptcha_validation_error: T('apiV2Error.recaptcha_validation_error'),
  recaptcha_wrong_hostname: T('apiV2Error.recaptcha_wrong_hostname'),
  recaptcha_wrong_token: T('apiV2Error.recaptcha_wrong_token'),
  rental_conditions_source_multiple: T(
    'apiV2Error.rental_conditions_source_multiple',
  ),
  retrieving_subscription: T('apiV2Error.retrieving_subscription'),
  stripe_intent_unknown: T('apiV2Error.stripe_intent_unknown'),
  stripe_verification_error: T('apiV2Error.stripe_verification_error'),
  tenancy_engagement_type_used: T('apiV2Error.tenancy_engagement_type_used'),
  tenancy_tenant_landlord_equal_uuid: T(
    'apiV2Error.tenancy_tenant_landlord_equal_uuid',
  ),
  user_account_deleted: T('apiV2Error.user_account_deleted'),
  user_already_verified: T('apiV2Error.user_already_verified'),
  user_code_expired: T('apiV2Error.user_code_expired'),
  user_code_used: T('apiV2Error.user_code_used'),
  user_federated_empty_id: T('apiV2Error.user_federated_empty_id'),
  user_federated_no_email: T('apiV2Error.user_federated_no_email'),
  user_i_d_not_verified: T('user_i_d_not_verified'),
  user_jwt_verification_error: T('apiV2Error.user_jwt_verification_error'),
  user_logged_in: T('apiV2Error.user_logged_in'),
  user_profile_email_already_registered: T(
    'apiV2Error.user_profile_email_already_registered',
  ),
  user_profile_email_forbidden: T('apiV2Error.user_profile_email_forbidden'),
  user_profile_passwords_do_not_match: T(
    'apiV2Error.user_profile_passwords_do_not_match',
  ),
  user_profile_phone_used: T('apiV2Error.user_profile_phone_used'),
  user_profile_rooms_less_than_listings: T(
    'apiV2Error.user_profile_rooms_less_than_listings',
  ),
  user_profile_users_not_found: T('apiV2Error.user_profile_users_not_found'),
  user_profile_verification_code_unmatch: T(
    'apiV2Error.user_profile_verification_code_unmatch',
  ),
  user_searches_country_code_city_required: T(
    'apiV2Error.user_searches_country_code_city_required',
  ),
  user_searches_price_range_invalid: T(
    'apiV2Error.user_searches_price_range_invalid',
  ),
  webhook_test_failed: T('apiV2Error.webhook_test_failed'),
};
