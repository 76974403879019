import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { User } from '@ha/api/v2/types/User';
import { UserInformation } from '@ha/api/v2/types/UserInformation';

import { AuthLogicTypes } from 'ha/modules/AuthLogic/constants';

export const AuthLogicActions = {
  updateUser: (payload: Partial<User>) =>
    createAction(AuthLogicTypes.updateUser, payload),
  initUser: (payload: { user: User; userInformation: UserInformation }) =>
    createAction(AuthLogicTypes.initUser, payload),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AuthLogicActions = ActionsUnion<typeof AuthLogicActions>;
