import React, { useEffect } from 'react';

import { useRouteError } from 'react-router-dom';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

import { Environment } from 'ha/config';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { LoadableHermesNotFoundPage } from 'ha/modules/Hermes/components/HermesNotFoundPage';
import { LoadableNotFoundPage } from 'ha/pages/NotFound';
import { LoadableSomethingWentWrongPage } from 'ha/pages/SomethingWentWrongPage';

interface ErrorResponse {
  status: number;
  statusText: string;
  data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  error?: Error;
  name?: string;
  type?: string;
  request?: string;
  message?: string;
}

export const RouterErrorBoundary: React.FC = () => {
  const error = useRouteError() as ErrorResponse;
  const track = useTrackEvent();

  useEffect(() => {
    const errorDetails = {
      request: error.request,
      type: error.type,
      msg: error.message,
      error,
      status: error.status,
    };

    track('Router Error Boundary', errorDetails);

    if (error.status !== HttpStatus.NOT_FOUND) {
      reportError(new Error('Router Error Boundary', { cause: error }), {
        metaData: errorDetails,
      });
    }
  }, [track, error]);

  if (process.env.NODE_ENV === Environment.DEVELOPMENT) {
    // make sure to print the original error when SSR fails
    // these errors end up being swallowed by the server otherwise and lead to hard to understand client errors
    // eslint-disable-next-line no-console
    console.error(
      'An error occurred while rendering the page on the server',
      JSON.stringify(error, null, 2),
    );
  }

  if (error.status === HttpStatus.NOT_FOUND) {
    if (error.data?.isHermes) {
      return <LoadableHermesNotFoundPage />;
    }

    return <LoadableNotFoundPage />;
  }

  return (
    <LoadableSomethingWentWrongPage
      pageName="RouterErrorBoundary"
      error={error}
    />
  );
};
