import { IntegrationNames } from '@hbf/analytics/lib/constants';

export const PII_PROPS = [
  'createdAt',
  'email',
  'firstName',
  'lastName',
  'phone',
];

export const ONLY_ROCKERBOX_INTEGRATION = {
  All: false,
  [IntegrationNames.Rockerbox]: true,
};

export const INTEGRATIONS_WITHOUT_ROCKERBOX = {
  All: true,
  [IntegrationNames.Rockerbox]: false,
};

export const EMPTY_USER_ID = '';
