import { MakeCall } from './createMakeCall';
import { Conversation } from './types/Conversation';

const acceptTenant =
  (makeCall: MakeCall) =>
  (conversationId: number, isGuarantorRequired?: boolean) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/accept-tenant`,
      isGuarantorRequired ? { data: { isGuarantorRequired } } : undefined,
    );

export { acceptTenant };
