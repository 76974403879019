import { FilterBuilder } from '../Types';

const unitTypeBuilder: FilterBuilder = ({ unitTypeIds = [] }) => {
  if (!unitTypeIds || !unitTypeIds.length) {
    return '';
  }

  const idsFilter = unitTypeIds
    .filter(id => id === 0 || Boolean(id))
    .map(id => {
      return `unitTypeInternalID=${id}`;
    });

  return idsFilter.join(' OR ');
};

export { unitTypeBuilder };
