import { Context, ROOT_CONTEXT, TextMapGetter } from '@opentelemetry/api';
import {
  ZoneContextManager,
  TargetWithEvents,
} from '@opentelemetry/context-zone';

export const defaultTextMapGetter: TextMapGetter = {
  get: (carrier: Record<string, string>, key: string) => carrier[key],
  keys: (carrier: Record<string, string>) => Object.keys(carrier),
};

/**
 * Instead of defaulting to the ROOT_CONTEXT will instead use any provided alternative context as the parent.
 * If inside another context provides that one instead.
 *
 * > Uses ZoneContextManager under the hood. */
export class AlternativeParentContextManager extends ZoneContextManager {
  context = ROOT_CONTEXT;

  constructor(alternativeContext: Context) {
    super();
    this.context = alternativeContext;
  }

  bind<T>(context: Context, target: T | TargetWithEvents): T {
    const isRoot = context === ROOT_CONTEXT;

    return super.bind(isRoot ? this.context : context, target);
  }

  active(): Context {
    const isRoot = super.active() === ROOT_CONTEXT;

    return isRoot ? this.context : super.active();
  }

  with<Func extends (...args: unknown[]) => ReturnType<Func>>(
    context: Context | null,
    fn: Func,
    self?: ThisParameterType<Func>,
    ...args: unknown[]
  ) {
    const isRoot = context === ROOT_CONTEXT;

    return super.with(isRoot ? this.context : context, fn, self, ...args);
  }
}
