import { isPast } from 'date-fns';

import { normalizeDate } from '@ha/date';

import { Cookies } from 'ha/constants/Cookies';
import { OCCUPATION } from 'ha/constants/Occupation';

import { CookieService } from 'ha/services/cookie';
import { Gender } from 'ha/types/Gender';
import { GenderValue, SuitableForValue } from 'ha/types/SearchFilters';

import {
  MatchmakingFilterProperties,
  SavedMatchmakingProperties,
} from './types';

export const occupationToFilterValues = (
  occupation: OCCUPATION,
): MatchmakingFilterProperties['suitableFor'] => {
  switch (occupation) {
    case OCCUPATION.workingProfessional:
      return [SuitableForValue.WORKING_PROFESSIONALS];
    case OCCUPATION.student:
      return [SuitableForValue.STUDENTS];
    default:
      return [];
  }
};

export const genderToFilterValues = (
  gender: Gender,
): MatchmakingFilterProperties['gender'] => {
  switch (gender) {
    case Gender.male:
      return [GenderValue.BOYS];
    case Gender.female:
      return [GenderValue.GIRLS];
    default:
      return [];
  }
};

export const getValidMoveInDate = (moveInDate?: string) => {
  if (!moveInDate) return '';
  const date = new Date(moveInDate);
  return isPast(date) ? normalizeDate(new Date().toISOString()) : moveInDate;
};

export const filterMatchmakingCookieHelper = {
  getCookieValue(cookie: CookieService) {
    return cookie.getCookie(Cookies.MATCHMAKING_FILTERS_SEARCH);
  },

  get(cookie: CookieService) {
    const cookieValue = this.getCookieValue(cookie);
    if (!cookieValue) return null;

    try {
      return JSON.parse(
        decodeURIComponent(cookieValue),
      ) as SavedMatchmakingProperties;
    } catch (error) {
      reportError(
        new Error('Failed to load filterMatchmakingCookie', { cause: error }),
      );
      return null;
    }
  },

  set(cookie: CookieService, options: Partial<SavedMatchmakingProperties>) {
    cookie.setCookie(
      Cookies.MATCHMAKING_FILTERS_SEARCH,
      JSON.stringify(options),
    );
  },

  remove(cookie: CookieService) {
    cookie.removeCookie(Cookies.MATCHMAKING_FILTERS_SEARCH);
  },

  /** merges with existing saved filters before setting */
  update(cookie: CookieService, options: Partial<SavedMatchmakingProperties>) {
    const savedFilters = this.get(cookie);
    if (!savedFilters) return;

    this.set(cookie, { ...savedFilters, ...options });
  },
};
