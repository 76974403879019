import { ExperimentType } from 'ha/modules/Experiments';

const Experiments: { [name: string]: ExperimentType } = {
  TNT478: {
    name: '[TNT-478] Flexible Search Dates',
    variants: ['A', 'B'],
    weights: [50, 50],
  },
  TNT809: {
    name: '[TNT-809] Integrate TopPlace location page on LDP map',
    variants: ['A', 'B'],
    weights: [50, 50],
  },
  TX233: {
    name: '[TX-233]: Show urgency on LDP',
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  /* Growth */
  MON2260: {
    name: '[MON-2260] Book-request with mandates',
    variants: ['A', 'B'],
    weights: [50, 50],
  },
  // Reorder payIN module sections
  MON2375: {
    name: '[MON-2375] Payment form arrangement',
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  LLX1427: {
    name: '[LLX-1427] Show remaining units block on LDP',
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  /* Trusted Advertier */
  LLX1472: {
    name: '[LLX-1472] Trusted advertiser experiment', // https://housinganywhere.atlassian.net/browse/LLX-1472
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  /* New Ranking */
  TX1828: {
    name: '[TX-1828] Use new ranking index', // https://housinganywhere.atlassian.net/browse/TX-1828
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  /* Redesign */
  TX2399: {
    name: '[TX-2399] New Payment Details Modal', // https://housinganywhere.atlassian.net/browse/TX-2399
    variants: ['A', 'B'],
    weights: [50, 50],
  },

  /* Rearrangement of IDP and Start Conversation */
  TX2255: {
    name: '[TX-2255] Rearrangement of IDP and Start Conversation', // https://housinganywhere.atlassian.net/browse/TX-2255
    variants: [
      'A', // Default
      'B', // Variant
    ],
    weights: [50, 50],
  },

  /* Show ID documents page in contact advertiser flow */
  BXP1812: {
    name: '[BXP-1812] Show ID documents page', // https://housinganywhere.atlassian.net/browse/BXP-1812
    variants: [
      'A', // Default
      'B', // Variant
    ],
    weights: [50, 50],
  },

  /* Replace success screen with the payments page */
  BXP1886: {
    name: '[BXP-1886] Skippable payment page', // https://housinganywhere.atlassian.net/browse/BXP-1886
    variants: [
      'A', // Default
      'B', // Variant
    ],
    weights: [50, 50],
  },

  TX3103: {
    name: '[TX-3103] Split View Desktop',
    variants: [
      'A', // Default (will Land into List View)
      'B', // Variant (will Land into Half-Map View)
    ],
    weights: [50, 50],
  },

  TX3104: {
    name: '[TX-3104] Split View Mobile',
    variants: [
      'C', // Default (not part of experiment)
      'A', // Variant (will land into List View)
      'B', // Variant (will land into Full Map View)
    ],
    weights: [90, 5, 5],
  },

  BXP2095: {
    name: '[BXP-2095] HA Powered Similar Listings',
    variants: [
      'A', // Default (uses Algolia recommendations)
      'B', // Variant (uses HA Data team recommendations)
    ],
    weights: [50, 50],
  },
};

export { Experiments };
