import { MakeCall } from './createMakeCall';

export enum GuarantorType {
  Own = 'own-guarantor',
  HA = 'ha-guarantor',
}

export interface SetBookingGuarantorPayload {
  guarantorType: GuarantorType;
}

const setBookingGuarantor =
  (makeCall: MakeCall) =>
  (conversationId: number, data: SetBookingGuarantorPayload) =>
    makeCall<void>(
      'PUT',
      `/api/v2/conversation/${conversationId}/set-guarantor`,
      {
        data,
      },
    );
export { setBookingGuarantor };
