import { reportError } from 'ha/helpers/bugReporter/reportError';

export const consentToCookiePopUpFromHaMobileApp = () => {
  try {
    if (window.isTrackingPermissionGranted) {
      window.OneTrust.AllowAll();
      return;
    }

    window.OneTrust.RejectAll();
  } catch (error) {
    reportError(
      new Error('Failed to consent to Cookie popup from mobile app', {
        cause: error?.message,
      }),
    );
  }
};
